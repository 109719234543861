import { type SVGProps } from 'react';
import { type IconName } from '@/icon-name';
import href from './icons/sprite.svg';

export { href };
export { IconName };

/**
 * Renders an SVG icon.
 */
export function Icon({
  name,
  ...props
}: SVGProps<SVGSVGElement> & {
  name: IconName;
}) {
  return (
    <svg aria-hidden={true} {...props}>
      <use href={`${href}#${name}`} />
    </svg>
  );
}
